<style lang="less" scoped>
.allInfo {
  background-color: #e3e1e1;
  padding: 0px 10px;
  font-weight: bold;
}

.report-content {
  padding: 1rem;
  .content-search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .search-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .team-item {
    display: flex;
    font-size: 16px;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    .team-item-label {
      min-width: 160px;
    }
  }
}
</style>

<template>
  <div class="report">
    <IndexHeader />
    <div class="report-content">
      <div class="content-tabs">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="医生统计" name="first">
            <div class="content-search">
              <div>
                按日期查询：
                <el-date-picker
                  v-model="dialogQueryDate"
                  type="daterange"
                  align="center"
                  size="mini"
                  style="margin-right: 10px"
                  unlink-panels
                  @change="handleNextDate"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </div>
              <div class="search-item">
                模块名称：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="moduleCode"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in modulelist"
                      :key="index"
                      :label="item.moduleTitle"
                      :value="item.moduleCode"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                科室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="sickArea"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in sickAreaList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术名称：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="operation"
                    :clearable="true"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteMethod"
                    placeholder="请选择"
                    :loading="surgeryNameLoading"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in pubOperationList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-refresh-left"
                class="commonBtn"
                v-debounce="clearTable"
              >
                清空
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                class="commonBtn"
                v-debounce="fetchData"
              >
                查询
              </el-button>
            </div>
            <el-table
              stripe
              v-loading="loading"
              :header-cell-style="{ padding: 0 }"
              :data="doctorQualityTable"
              border
              :height="tableHeight - 30"
              :span-method="handleSpanMethod"
            >
              <el-table-column
                header-align="center"
                label="医生"
                align="center"
                prop="doctorName"
                width="150"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="科室"
                align="center"
                prop="deptName"
                width="150"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="手术名称"
                align="center"
                prop="examineName"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="台数"
                align="center"
                prop="number"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="平均完成度"
                align="center"
                prop="completion"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="必填项完成度"
                align="center"
                prop="notnullCompletion"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="100%必填项完成数"
                align="center"
                prop="notnullCompleted100"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="100%完成数"
                align="center"
                prop="completed100"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="80%完成数"
                align="center"
                prop="completed80"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="60%完成数"
                align="center"
                prop="completed60"
              ></el-table-column>
            </el-table>
            <div class="allInfo" v-if="doctorQualityTable.length > 0">
              总计手术:{{ doctorQualityTable.length }}
            </div>
          </el-tab-pane>
          <el-tab-pane label="团队统计" name="second">
            <div class="content-search">
              <div>
                按日期查询：
                <el-date-picker
                  v-model="dialogQueryDate"
                  type="daterange"
                  align="center"
                  size="mini"
                  style="margin-right: 10px"
                  unlink-panels
                  @change="handleNextDate"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </div>
              <div class="search-item">
                模块名称：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="moduleCode"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in modulelist"
                      :key="index"
                      :label="item.moduleTitle"
                      :value="item.moduleCode"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                科室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="sickArea"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in sickAreaList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术名称：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="operation"
                    :clearable="true"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteMethod"
                    placeholder="请选择"
                    :loading="surgeryNameLoading"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in pubOperationList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-refresh-left"
                class="commonBtn"
                v-debounce="clearTable"
              >
                清空
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                class="commonBtn"
                v-debounce="fetchData"
              >
                查询
              </el-button>
            </div>
            <el-table
              stripe
              v-loading="loading"
              :header-cell-style="{ padding: 0 }"
              :data="teamQualityTable"
              border
              :height="tableHeight - 30"
              :span-method="handleSpanMethod1"
            >
              <el-table-column
                header-align="center"
                label="团队名称"
                align="center"
                prop="teamName"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="成员名称"
                align="center"
                prop="userName"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="术中完成度"
                align="center"
                prop="completion"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="术中最大完成度"
                align="center"
                prop="maxCompletion"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="术中最小完成度"
                align="center"
                prop="minCompletion"
              ></el-table-column>
            </el-table>
            <div class="allInfo" v-if="teamQualityTable.length > 0">
              总计团队:{{ teamQualityTable.length }}
            </div>
          </el-tab-pane>
          <el-tab-pane label="手术统计" name="third">
            <div class="content-search">
              <div>
                按日期查询：
                <el-date-picker
                  v-model="dialogQueryDate"
                  type="daterange"
                  align="center"
                  size="mini"
                  style="margin-right: 10px"
                  unlink-panels
                  @change="handleNextDate"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </div>
              <div class="search-item">
                模块名称：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="moduleCode"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in modulelist"
                      :key="index"
                      :label="item.moduleTitle"
                      :value="item.moduleCode"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                科室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="sickArea"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in sickAreaList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术名称：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="operation"
                    :clearable="true"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteMethod"
                    placeholder="请选择"
                    :loading="surgeryNameLoading"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in pubOperationList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-refresh-left"
                class="commonBtn"
                v-debounce="clearTable"
              >
                清空
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                class="commonBtn"
                v-debounce="fetchData"
              >
                查询
              </el-button>
            </div>
            <el-table
              stripe
              v-loading="loading"
              :header-cell-style="{ padding: 0 }"
              :data="operationQualityTable"
              border
              :height="tableHeight - 30"
            >
              <el-table-column
                header-align="center"
                label="手术日期"
                align="center"
                prop="studyDate"
                width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.studyDate?.substr(0, 10) }}
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                label="患者姓名"
                align="center"
                prop="patientName"
                width="120"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="手术名称"
                align="center"
                prop="examineNames"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    type="info"
                    size="mini"
                    style="margin-right: 10px"
                    v-for="(item, index) in scope.row.examineNames.split('|')"
                    :key="index"
                  >
                    {{ item }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                label="主刀医生"
                align="center"
                prop="examineDoctor"
                width="120"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="术前评估必填项完成度"
                align="center"
                prop="sqpgNotnullCompletion"
                width="150"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="术前评估完成度"
                align="center"
                prop="sqpgCompletion"
                width="120"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="术前检查必填项完成度"
                align="center"
                prop="sqjcNotnullCompletion"
                width="150"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="术前检查完成度"
                align="center"
                prop="sqjcCompletion"
                width="120"
              ></el-table-column>

              <el-table-column
                header-align="center"
                label="介入护理"
                align="center"
                prop="haveReport"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="用药记录"
                align="center"
                prop="haveMedication"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="耗材记录"
                align="center"
                prop="haveEquipment"
                show-overflow-tooltip
              ></el-table-column>

              <el-table-column
                header-align="center"
                label="术中完成度"
                align="center"
                prop="universalCompletion"
                width="100"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="术后评估必填项完成度"
                align="center"
                prop="shpgNotnullCompletion"
                width="150"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="术后评估完成度"
                align="center"
                prop="shpgCompletion"
                width="120"
              ></el-table-column>
            </el-table>
            <div class="allInfo" v-if="operationQualityTable.length > 0">
              总计手术:{{ operationQualityTable.length }}
            </div>
          </el-tab-pane>
          <el-tab-pane label="随访统计" name="fourth">
            <div class="content-search">
              <div>
                按日期查询：
                <el-date-picker
                  v-model="dialogQueryDate"
                  type="daterange"
                  align="center"
                  size="mini"
                  style="margin-right: 10px"
                  unlink-panels
                  @change="handleNextDate"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </div>
              <div class="search-item">
                模块名称：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="moduleCode"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in modulelist"
                      :key="index"
                      :label="item.moduleTitle"
                      :value="item.moduleCode"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                科室：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="sickArea"
                    :clearable="true"
                    placeholder="请选择"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in sickAreaList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="search-item">
                手术名称：
                <div style="margin-right: 10px">
                  <el-select
                    v-model="operation"
                    :clearable="true"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteMethod"
                    placeholder="请选择"
                    :loading="surgeryNameLoading"
                    @change="changeSearchSurgeryName"
                  >
                    <el-option
                      v-for="(item, index) in pubOperationList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-refresh-left"
                class="commonBtn"
                v-debounce="clearTable"
              >
                清空
              </el-button>
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                class="commonBtn"
                v-debounce="fetchData"
              >
                查询
              </el-button>
            </div>
            <el-table
              stripe
              v-loading="loading"
              :header-cell-style="{ padding: 0 }"
              :data="followQualityTable"
              border
              :height="tableHeight - 30"
            >
              <el-table-column
                header-align="center"
                label="患者姓名"
                align="center"
                prop="patientName"
                width="180"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="手术名称"
                align="center"
                prop="examineNames"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="手术时间"
                align="center"
                prop="studyDate"
                width="240"
              >
                <template slot-scope="scope">
                  {{ scope.row.studyDate?.substr(0, 10) }}
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                label="主刀医生"
                align="center"
                prop="examineDoctor"
                width="240"
              ></el-table-column>

              <el-table-column
                header-align="center"
                label="随访时间"
                align="center"
                prop="followDate"
                width="240"
              >
                <template slot-scope="scope">
                  {{ scope.row.followDate?.substr(0, 10) }}
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                label="随访标题"
                align="center"
                prop="followTitle"
                width="240"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="随访完成度"
                align="center"
                prop="completion"
                width="160"
              ></el-table-column>
              <el-table-column
                header-align="center"
                label="随访必填项完成度"
                align="center"
                prop="notnullCompletion"
                width="160"
              ></el-table-column>
            </el-table>
            <div class="allInfo" v-if="followQualityTable.length > 0">
              总计随访:{{ followQualityTable.length }}
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import IndexHeader from '@/components/IndexHeader'
import { getBeforeDate } from '../../utils'

export default {
  name: 'QualityReport',
  components: {
    IndexHeader
  },
  data() {
    return {
      tableHeight: window.innerHeight - 200, // 表格动态高度
      screenHeight: window.innerHeight, // 内容区域高度
      loading: false,
      queryGet: '',
      changeValue: '',
      activeName: 'first',
      dialogQueryDate: null,
      sickArea: null,
      sickAreaList: [],
      operation: null,
      pubOperationList: [],
      teamQualityTable: [],
      operationQualityTable: [],
      doctorQualityTable: [],
      followQualityTable: [],
      moduleCode: null,
      modulelist: [],
      surgeryNameLoading: false,
      // 合并单元格
      column1Arr: [], // column1
      column1Index: 0, // column1索引
      column2Arr: [], // column2
      column2Index: 0, // column2索引
      column3Arr: [], // column3
      column3Index: 0, // column3索引
      column4Arr: [], // column4
      column4Index: 0, // column4
      column5Arr: [], // column5
      column5Index: 0, // column5索引
      column6Arr: [], // column6
      column6Index: 0, // column6索引
      // 导入日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              end.setTime(end.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  created() {},
  mounted() {
    this.getData()
    this.fetchData()
    this.getSickAreaList()
    this.getPubOperation()
    this.getMouduleList()
    window.onresize = () => {
      return () => {
        // window.innerHeight:浏览器的可用高度
        window.screenHeight = window.innerHeight
        this.screenHeight = window.screenHeight
      }
    }
  },
  computed: {},
  props: {},
  watch: {
    // 监听screenHeight从而改变table的高度
    screenHeight(val) {
      this.screenHeight = val
      this.tableHeight = this.screenHeight - 200
    }
  },
  methods: {
    changeSearchSurgeryName(val) {
      if (val != undefined) {
        // 判断值和变量,如果之和变量相等,则不做操作,否则执行
        if (this.changeValue == val) {
          return
        } else {
          // 储存我所改变的值
          this.changeValue = val
          this.fetchData()
        }
      }
    },
    handleSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        // 第一列 column1
        const _row_1 = this.column1Arr[rowIndex]
        const _col_1 = _row_1 > 0 ? 1 : 0
        return {
          rowspan: _row_1,
          colspan: _col_1
        }
      } else if (columnIndex === 2) {
        // 第二列 column2
        const _row_2 = this.column2Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 3) {
        // 第三列 column3
        const _row_2 = this.column3Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 4) {
        // 第四列 column4
        const _row_2 = this.column4Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 5) {
        // 第五列 column5
        const _row_2 = this.column5Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 6) {
        // 第六列 column6
        const _row_2 = this.column6Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      }
    },
    handleSpanMethod1({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        // 第一列 column1
        const _row_1 = this.column1Arr[rowIndex]
        const _col_1 = _row_1 > 0 ? 1 : 0
        return {
          rowspan: _row_1,
          colspan: _col_1
        }
      } else if (columnIndex === 1) {
        // 第二列 column2
        const _row_2 = this.column2Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 2) {
        // 第三列 column3
        const _row_2 = this.column3Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 3) {
        // 第四列 column4
        const _row_2 = this.column4Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 4) {
        // 第五列 column5
        const _row_2 = this.column5Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      } else if (columnIndex === 5) {
        // 第六列 column6
        const _row_2 = this.column6Arr[rowIndex]
        const _col_2 = _row_2 > 0 ? 1 : 0
        return {
          rowspan: _row_2,
          colspan: _col_2
        }
      }
    },
    handleNextDate() {
      this.fetchData()
    },

    clearTable() {
      this.dialogQueryDate = []
      this.sickArea = null
      this.operation = null
      if (this.activeName !== 'fourth') {
        this.moduleCode = null
      }
      this.teamQualityTable = []
      this.operationQualityTable = []
      this.doctorQualityTable = []
      this.followQualityTable = []

      this.fetchData()
    },
    // 选择tabs
    handleClick() {
      this.dialogQueryDate = []
      this.sickArea = null
      this.operation = null
      if (this.activeName === 'fourth') {
        this.moduleCode = this.modulelist[0].moduleCode
      } else {
        this.moduleCode = null
      }
      this.getData()

      this.teamQualityTable = []
      this.operationQualityTable = []
      this.doctorQualityTable = []
      this.followQualityTable = []

      this.fetchData()
    },

    remoteMethod(query) {
      if (query !== '') {
        this.surgeryNameLoading = true
        setTimeout(() => {
          this.surgeryNameLoading = false
          this.getPubOperation(query)
        }, 200)
      } else {
        this.pubOperationList = []
      }
    },

    getPubOperation(query) {
      if (query) {
        this.queryGet = query
      }
      this.$api
        .get(`/v1/webconsole/study/puboperationlist?keyword=${this.queryGet}`)
        .then((res) => {
          let arr = []
          if (res.data.data.length > 0) {
            res.data.data.map((item) => {
              arr.push({ label: item.dictName, value: item.dictName })
            })
            this.pubOperationList = arr
          }
        })
    },

    getSickAreaList() {
      this.$api.get(`/v1/webconsole/user/depts`).then((res) => {
        if (res.data && res.data.data.length > 0) {
          let labArr = []
          res.data.data.map((item) => {
            labArr.push({ label: item.name, value: item.name })
          })
          this.sickAreaList = labArr
        }
      })
    },

    getMouduleList() {
      this.$api.get(`/v1/webconsole/study/mymodulelist?moduleType=1`).then((res) => {
        if (res.data && res.data.data.length > 0) {
          this.modulelist = res.data.data
        }
      })
    },

    getData() {
      let nextDateStart = new Date()
      let nextDateEnd = new Date()
      this.dialogQueryDate = [
        getBeforeDate(0) + ' ' + '00:00:00',
        nextDateEnd.getFullYear() +
          '-' +
          (nextDateEnd.getMonth() + 1 < 10 ? '0' : '') +
          (nextDateEnd.getMonth() + 1) +
          '-' +
          (nextDateStart.getDate() + 1 < 10 ? '0' : '') +
          nextDateStart.getDate() +
          ' ' +
          '00:00:00'
      ]
    },

    fetchData() {
      this.loading = true

      this.teamQualityTable = []
      this.operationQualityTable = []
      this.doctorQualityTable = []
      this.followQualityTable = []
      if (this.activeName === 'second') {
        let params = {
          moduleCode: this.moduleCode || null,
          sickArea: this.sickArea || null,
          examineName: this.operation || null,
          startTime: this.dialogQueryDate ? this.dialogQueryDate[0] : null,
          endTime: this.dialogQueryDate ? this.dialogQueryDate[1] : null
        }
        let formData = new FormData()
        for (let i in params) {
          if (params[i]) {
            formData.append(i, params[i])
          }
        }
        this.$api
          .post(`/v1/webconsole/qos/team`, formData)
          .then((res) => {
            if (res.data.data.length > 0 && res.data.status === 200) {
              this.mergeInit()
              const newTableData = res.data.data
              newTableData.map((r, index) => {
                const parentId = index
                this.teamQualityTable.push.apply(
                  this.teamQualityTable,
                  this.handleData([r], parentId)
                )
              })

              this.mergeTable1(this.teamQualityTable)
            }

            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      } else if (this.activeName === 'third') {
        let params = {
          moduleCode: this.moduleCode || null,
          sickArea: this.sickArea || null,
          examineName: this.operation || null,
          startTime: this.dialogQueryDate ? this.dialogQueryDate[0] : null,
          endTime: this.dialogQueryDate ? this.dialogQueryDate[1] : null
        }
        let formData = new FormData()
        for (let i in params) {
          if (params[i]) {
            formData.append(i, params[i])
          }
        }

        this.$api
          .post(`/v1/webconsole/qos/operation`, formData)
          .then((res) => {
            this.operationQualityTable = res.data.data

            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      } else if (this.activeName === 'first') {
        let params = {
          moduleCode: this.moduleCode || null,
          sickArea: this.sickArea || null,
          examineName: this.operation || null,
          startTime: this.dialogQueryDate ? this.dialogQueryDate[0] : null,
          endTime: this.dialogQueryDate ? this.dialogQueryDate[1] : null
        }
        let formData = new FormData()
        for (let i in params) {
          if (params[i]) {
            formData.append(i, params[i])
          }
        }

        this.$api
          .post(`/v1/webconsole/qos/doctor`, formData)
          .then((res) => {
            if (res.data.data.length > 0 && res.data.status === 200) {
              this.mergeInit()
              const newTableData = res.data.data
              newTableData.map((r, index) => {
                const parentId = index
                this.doctorQualityTable.push.apply(
                  this.doctorQualityTable,
                  this.handleData([r], parentId)
                )
              })

              this.mergeTable(this.doctorQualityTable)
            }

            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      } else if (this.activeName === 'fourth' && this.moduleCode) {
        let params = {
          moduleCode: this.moduleCode || null,
          sickArea: this.sickArea || null,
          examineName: this.operation || null,
          startTime: this.dialogQueryDate ? this.dialogQueryDate[0] : null,
          endTime: this.dialogQueryDate ? this.dialogQueryDate[1] : null
        }
        let formData = new FormData()
        for (let i in params) {
          if (params[i]) {
            formData.append(i, params[i])
          }
        }

        this.$api
          .post(`/v1/webconsole/qos/follow`, formData)
          .then((res) => {
            if (res.data.data.length > 0 && res.data.status === 200) {
              this.mergeInit()
              const newTableData = res.data.data
              newTableData.map((r, index) => {
                const parentId = index
                this.followQualityTable.push.apply(
                  this.followQualityTable,
                  this.handleData([r], parentId)
                )
              })

              this.mergeTable(this.followQualityTable)
            }

            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    // 合并表格
    mergeTable(data) {
      this.mergeInit()
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            // 第一行必须存在，以第一行为基准
            this.column1Arr.push(1) // column1
            this.column1Index = 0

            this.column2Arr.push(1) // column2
            this.column2Index = 0

            this.column3Arr.push(1) // column3
            this.column3Index = 0

            this.column4Arr.push(1) // column4
            this.column4Index = 0

            this.column5Arr.push(1) // column5
            this.column5Index = 0

            this.column6Arr.push(1) // column6
            this.column6Index = 0
          } else {
            // 判断当前元素与上一元素是否相同
            // column1
            if (data[i].doctorName === data[i - 1].doctorName && data[i].id === data[i - 1].id) {
              this.column1Arr[this.column1Index] += 1
              this.column1Arr.push(0)
            } else {
              this.column1Arr.push(1)
              this.column1Index = i
            }

            // column2
            if (data[i].deptName === data[i - 1].deptName && data[i].id === data[i - 1].id) {
              this.column2Arr[this.column2Index] += 0
              this.column2Arr.push(1)
            } else {
              this.column2Arr.push(1)
              this.column2Index = i
            }

            // column3
            if (data[i].examineName === data[i - 1].examineName && data[i].id === data[i - 1].id) {
              this.column3Arr[this.column3Index] += 0
              this.column3Arr.push(1)
            } else {
              this.column3Arr.push(1)
              this.column3Index = i
            }

            // column4
            if (data[i].examineName === data[i - 1].examineName && data[i].id === data[i - 1].id) {
              this.column4Arr[this.column4Index] += 0
              this.column4Arr.push(1)
            } else {
              this.column4Arr.push(1)
              this.column4Index = i
            }

            // column5
            if (data[i].examineName === data[i - 1].examineName && data[i].id === data[i - 1].id) {
              this.column5Arr[this.column5Index] += 0
              this.column5Arr.push(1)
            } else {
              this.column5Arr.push(1)
              this.column5Index = i
            }

            // column6
            if (data[i].examineName === data[i - 1].examineName && data[i].id === data[i - 1].id) {
              this.column6Arr[this.column6Index] += 0
              this.column6Arr.push(1)
            } else {
              this.column6Arr.push(1)
              this.column6Index = i
            }
          }
        }
      }
    },
    // 合并表格
    mergeTable1(data) {
      this.mergeInit()
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            // 第一行必须存在，以第一行为基准
            this.column1Arr.push(1) // column1
            this.column1Index = 0

            this.column2Arr.push(1) // column2
            this.column2Index = 0

            this.column3Arr.push(1) // column3
            this.column3Index = 0

            this.column4Arr.push(1) // column4
            this.column4Index = 0

            this.column5Arr.push(1) // column5
            this.column5Index = 0

            this.column6Arr.push(1) // column6
            this.column6Index = 0
          } else {
            // 判断当前元素与上一元素是否相同
            // column1
            if (data[i].teamName === data[i - 1].teamName && data[i].id === data[i - 1].id) {
              this.column1Arr[this.column1Index] += 1
              this.column1Arr.push(0)
            } else {
              this.column1Arr.push(1)
              this.column1Index = i
            }

            // column2
            if (data[i].userName === data[i - 1].userName && data[i].id === data[i - 1].id) {
              this.column2Arr[this.column2Index] += 0
              this.column2Arr.push(1)
            } else {
              this.column2Arr.push(1)
              this.column2Index = i
            }

            // column3
            if (data[i].userName === data[i - 1].userName && data[i].id === data[i - 1].id) {
              this.column3Arr[this.column3Index] += 0
              this.column3Arr.push(1)
            } else {
              this.column3Arr.push(1)
              this.column3Index = i
            }

            // column4
            if (data[i].userName === data[i - 1].userName && data[i].id === data[i - 1].id) {
              this.column4Arr[this.column4Index] += 0
              this.column4Arr.push(1)
            } else {
              this.column4Arr.push(1)
              this.column4Index = i
            }

            // column5
            if (data[i].userName === data[i - 1].userName && data[i].id === data[i - 1].id) {
              this.column5Arr[this.column5Index] += 0
              this.column5Arr.push(1)
            } else {
              this.column5Arr.push(1)
              this.column5Index = i
            }

            // column6
            if (data[i].userName === data[i - 1].userName && data[i].id === data[i - 1].id) {
              this.column6Arr[this.column6Index] += 0
              this.column6Arr.push(1)
            } else {
              this.column6Arr.push(1)
              this.column6Index = i
            }
          }
        }
      }
    },
    // table 表格数据初始化处理，将树结构数据转为一维数组
    handleData(data, parentId) {
      data.map((res, index) => {
        var obj = {
          id: parentId,
          ...data[0]
        }
        for (const key in res) {
          const isarr = Object.values(res).find((age) => {
            return Array.isArray(age)
          })
          if (isarr) {
            if (Array.isArray(res[key])) {
              for (let i = 0; i < res[key].length; i++) {
                Object.assign(obj, res[key][i])
                data.push(obj)
                res[key].splice(i, 1)
                if (res[key].length === 0) {
                  data.splice(index, 1)
                }
                this.handleData(data, parentId)
              }
            } else {
              Object.assign(obj, { [key]: res[key] })
            }
          }
        }
      })
      return data
    },
    // 初始化合并行数组
    mergeInit() {
      this.column1Arr = [] // column1
      this.column1Index = 0 // column1索引
      this.column2Arr = [] // column2
      this.column2Index = 0 // column2索引
      this.column3Arr = [] // column3
      this.column3Index = 0 // column3索引
      this.column4Arr = [] // column4
      this.column4Index = 0 // column4索引
      this.column5Arr = [] // column5
      this.column5Index = 0 // column5索引
      this.column6Arr = [] // column6
      this.column6Index = 0 // column6索引
    }
  },
  beforeDestroy() {}
}
</script>
